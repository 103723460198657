<template>
    <main>
        <h1>Log activity<router-link to="/calendar"><img class="close" :src="'./assets/add.svg'"/></router-link></h1>
        <div><h2>Time...</h2>
            <input type="datetime-local" v-model="time"/>
        </div>
        <div><h2>Activity...</h2>
            <input type="radio" id="sex" name="activity" value="sex" v-model="activity"/>
            <label class="smallimage" for="sex"><img :src="'./assets/sex.svg'"/><span>Sex</span></label>
            <input type="radio" id="masturbation" name="activity" value="masturbation" v-model="activity"/>
            <label class="smallimage" v-if="gender == 'penis'" for="masturbation"><img :src="'./assets/m_masturbation.svg'"/><span>Masturbation</span></label>
            <label class="smallimage" v-if="gender == 'vagina'" for="masturbation"><img :src="'./assets/f_masturbation.svg'"/><span>Masturbation</span></label>
        </div>
        <div><h2>Duration...</h2>
            <input v-model="duration" type="radio" id="5" name="duration" value="5"/>
            <label for="5">5 min</label>
            <input v-model="duration" type="radio" id="10" name="duration" value="10"/>
            <label for="10">10 min</label>
            <input v-model="duration" type="radio" id="15" name="duration" value="15"/>
            <label for="15">15 min</label><br/>
            <input v-model="duration" type="radio" id="20" name="duration" value="20"/>
            <label for="20">20 min</label>
            <input v-model="duration" type="radio" id="30" name="duration" value="30"/>
            <label for="30">30 min</label>
            <input v-model="duration" type="radio" id="45" name="duration" value="45"/>
            <label for="45">45 min</label>
            <input v-model="duration" type="radio" id="60" name="duration" value="60"/>
            <label for="60">1h</label>
        </div>
        <div v-if="activity!='masturbation'"><h2>Preventation...</h2>
            <input v-model="preventation" type="checkbox" id="condom" name="preventation" value="condom"/>
            <label class="smallimage" for="condom"><img :src="'./assets/condom.svg'"/><span>Condom</span></label>
            <input v-model="preventation" type="checkbox" id="pill" name="preventation" value="pill"/>
            <label class="smallimage" for="pill"><img :src="'./assets/pill.svg'"/><span>Pill</span></label>
            <input v-model="preventation" type="checkbox" id="patches" name="preventation" value="patches"/>
            <label class="smallimage" for="patches"><img :src="'./assets/patch.svg'"/><span>Patch</span></label><br/>
            <input v-model="preventation" type="checkbox" id="injection" name="preventation" value="injection"/>
            <label class="smallimage" for="injection"><img :src="'./assets/injection.svg'"/><span>Injection</span></label>
            <input v-model="preventation" type="checkbox" id="ring" name="preventation" value="ring"/>
            <label class="smallimage" for="ring"><img :src="'./assets/ring.svg'"/><span>Ring</span></label>
            <input v-model="preventation" type="checkbox" id="IUD" name="preventation" value="IUD"/>
            <label class="smallimage" for="IUD"><img :src="'./assets/iud.svg'"/><span>IUD</span></label><br/>
            <input v-model="preventation" type="checkbox" id="implants" name="preventation" value="implants"/>
            <label class="smallimage" for="implants"><img :src="'./assets/implant.svg'"/><span>Implant</span></label>
            <input v-model="preventation" type="checkbox" id="temperature" name="preventation" value="temperature"/>
            <label class="smallimage" for="temperature"><img :src="'./assets/temperature.svg'"/><span>Temperature</span></label>
            <input v-model="preventation" type="checkbox" id="sterilitation" name="preventation" value="sterilitation"/><br/>
            <label class="smallimage" for="sterilitation"><img :src="'./assets/sterilization.svg'"/><span>Sterilization</span></label>
        </div>
        <div><h2>Location...</h2>
            <input v-model="location" type="checkbox" id="bed" name="location" value="bed"/>
            <label class="smallimage" for="bed"><img :src="'./assets/bed.svg'"/><span>Bed</span></label>
            <input v-model="location" type="checkbox" id="kitchen" name="location" value="kitchen"/>
            <label class="smallimage" for="kitchen"><img :src="'./assets/kitchen.svg'"/><span>Kitchen</span></label><br/>
            <input v-model="location" type="checkbox" id="livingroom" name="location" value="livingroom"/>
            <label class="smallimage" for="livingroom"><img :src="'./assets/livingroom.svg'"/><span>Livingroom</span></label>
            <input v-model="location" type="checkbox" id="shower" name="location" value="shower"/>
            <label class="smallimage" for="shower"><img :src="'./assets/shower.svg'"/><span>Shower</span></label><br/>
            <input v-model="location" type="checkbox" id="bathroom" name="location" value="bathroom"/>
            <label class="smallimage" for="bathroom"><img :src="'./assets/toilet.svg'"/><span>Bathroom</span></label>
            <input v-model="location" type="checkbox" id="office" name="location" value="office"/>
            <label class="smallimage" for="office"><img :src="'./assets/office.svg'"/><span>Office</span></label><br/>
            <input v-model="location" type="checkbox" id="car" name="location" value="car"/>
            <label class="smallimage" for="car"><img :src="'./assets/car.svg'"/><span>Car</span></label>
            <input v-model="location" type="checkbox" id="outdoors" name="location" value="outdoors"/>
            <label class="smallimage" for="outdoors"><img :src="'./assets/outdoors.svg'"/><span>Outdoors</span></label>
        </div>
        <div v-if="activity!='masturbation'"><h2>Position...</h2>
            <input v-model="position" type="checkbox" id="missionary" name="position" value="missionary">
            <label class="smallimage" for="missionary"><img style="width: 40px;" :src="'./assets/missionary.svg'"/><span>Missionary</span></label>
            <input v-model="position" type="checkbox" id="doggy" name="position" value="doggy">
            <label class="smallimage" for="doggy"><img :src="'./assets/doggy.svg'"/><span>Doggy</span></label><br/>
            <input v-model="position" type="checkbox" id="69" name="position" value="69">
            <label class="smallimage" for="69"><img style="width: 40px;" :src="'./assets/69.svg'"/><span>69</span></label>
            <input v-model="position" type="checkbox" id="cowgirl" name="position" value="cowgirl">
            <label class="smallimage" for="cowgirl"><img :src="'./assets/cowgirl.svg'"/><span>Cowgirl</span></label>
            <input v-model="position" type="checkbox" id="spooning" name="position" value="spooning">
            <label class="smallimage" for="spooning"><img :src="'./assets/spooning.svg'"/><span>Spooning</span></label><br/>
            <input v-model="position" type="checkbox" id="wheelbarrow" name="position" value="wheelbarrow">
            <label class="smallimage" for="wheelbarrow"><img :src="'./assets/wheelbarrow.svg'"/><span>Wheelbarrow</span></label>
            <input v-model="position" type="checkbox" id="pogostick" name="position" value="pogostick">
            <label class="smallimage" for="pogostick"><img :src="'./assets/pogostick.svg'"/><span>Pogo stick</span></label><br/>
            <input v-model="position" type="checkbox" id="scissoring" name="position" value="scissoring">
            <label class="smallimage" for="scissoring"><img :src="'./assets/scissoring.svg'"/><span>Scissoring</span></label>
            <input v-model="position" type="checkbox" id="anal" name="position" value="anal">
            <label class="smallimage" for="anal"><img :src="'./assets/anal.svg'"/><span>Anal</span></label><br/>
            <input v-model="position" type="checkbox" id="blowjob" name="position" value="blowjob">
            <label class="smallimage" for="blowjob"><img :src="'./assets/blowjob.svg'"/><span>Blowjob</span></label>
            <input v-model="position" type="checkbox" id="handjob" name="position" value="handjob">
            <label class="smallimage" for="handjob"><img :src="'./assets/handjob.svg'"/><span>Handjob</span></label><br/>
            <input v-model="position" type="checkbox" id="licking" name="position" value="licking">
            <label class="smallimage" for="licking"><img :src="'./assets/licking.svg'"/><span>Licking</span></label>
            <input v-model="position" type="checkbox" id="fingering" name="position" value="fingering">
            <label class="smallimage" for="fingering"><img :src="'./assets/fingering.svg'"/><span>Fingering</span></label>
        </div>
        <div v-if="activity!='masturbation'"><h2>Partner...</h2>
            <input type="radio" id="penis" name="gender" value="penis" v-model="partnergender"/>
            <label class="smallimage" for="penis"><img :src="'./assets/penis.svg'"/><span>Penis</span></label>
            <input type="radio" id="vagina" name="gender" value="vagina" v-model="partnergender"/>
            <label class="smallimage" for="vagina"><img :src="'./assets/vagina.svg'"/><span>Vagina</span></label><br/>
            <input type="text" id="name" name="duration" placeholder="Name" v-model="partnername"><br/>
            <country-select v-model:country="partnernationality"></country-select>
        </div>
        <div><h2>using...</h2>
            <input v-model="toys" type="checkbox" id="porn" name="tools" value="porn"/>
            <label class="smallimage" for="porn"><img :src="'./assets/porn.svg'"/><span>Porn</span></label>
            <input v-model="toys" type="checkbox" id="alcohol" name="tools" value="alcohol"/>
            <label class="smallimage" for="alcohol"><img :src="'./assets/alcohol.svg'"/><span>Alcohol</span></label>
            <input v-model="toys" type="checkbox" id="drugs" name="tools" value="drugs"/>
            <label class="smallimage" for="drugs"><img :src="'./assets/drugs.svg'"/><span>Drugs</span></label><br/>
            <input v-model="toys" type="checkbox" id="dildo" name="tools" value="dildo"/>
            <label class="smallimage" for="dildo"><img :src="'./assets/dildo.svg'"/><span>Dildo</span></label>
            <input v-model="toys" type="checkbox" id="penisring" name="tools" value="penisring"/>
            <label class="smallimage" for="penisring"><img :src="'./assets/penisring.svg'"/><span>Penis ring</span></label>
            <input v-model="toys" type="checkbox" id="food" name="tools" value="food"/>
            <label class="smallimage" for="food"><img :src="'./assets/food.svg'"/><span>Food</span></label><br/>
            <input v-model="toys" type="checkbox" id="lubrication" name="tools" value="lubrication"/>
            <label class="smallimage" for="lubrication"><img :src="'./assets/lubrication.svg'"/><span>Lubrication</span></label>
            <input v-model="toys" type="checkbox" id="rope" name="tools" value="rope"/>
            <label class="smallimage" for="rope"><img :src="'./assets/rope.svg'"/><span>Rope</span></label><br/>
            <input v-model="toys" type="checkbox" id="handcuffs" name="tools" value="handcuffs"/>
            <label class="smallimage" for="handcuffs"><img :src="'./assets/handcuffs.svg'"/><span>Handcuffs</span></label>
            <input v-model="toys" type="checkbox" id="bdsm" name="tools" value="bdsm"/>
            <label class="smallimage" for="bdsm"><img :src="'./assets/bdsm.svg'"/><span>BDSM</span></label>
        </div>
        <button :disabled="buttonDisabled" @click="saveActivity">save</button>
    </main>
</template>

<script>
import countrySelect from '../components/countrySelect.vue';
let activity, time, duration, partnername, partnernationality, partnergender;
let preventation = [], location = [], position = [], toys = [];
export default {
    name: 'addActivityView',
    components: {countrySelect},
    props: {
        gender: String
    },
    data: function() {
        return {
            activity,
            time,
            duration,
            preventation,
            location,
            position,
            partnername,
            partnernationality,
            partnergender,
            toys
        }
    },
    methods: {
        saveActivity() {
            let allActivites = JSON.parse(localStorage.getItem('activites'));
            if(allActivites == undefined) {allActivites = [];}
            let currentActivity = {};
            if(this.activity == 'sex') {
                currentActivity = {
                    time : this.time, 
                    activity : this.activity,
                    duration : this.duration,
                    preventation : this.preventation,
                    location : this.location,
                    position : this.position,
                    partner: {
                        name: this.partnername,
                        gender: this.partnergender,
                        nationality: this.partnernationality
                        },
                    toys : this.toys
                }
                localStorage.setItem('partner', JSON.stringify({name: this.partnername, gender: this.partnergender, nationality: this.partnernationality}));
            }
            else {
                currentActivity = {
                    time : this.time, 
                    activity : this.activity,
                    duration : this.duration,
                    location : this.location,
                    toys : this.toys
                }
            }
            allActivites.push(currentActivity);
            localStorage.setItem('activites', JSON.stringify(allActivites));
            this.$router.push('calendar');
        }
    },
    computed: {
        buttonDisabled() {
            return this.activity == undefined;
        }
    },
    created: function() {
        if(this.gender == '') {
            this.$router.push('/genderSelect')
        }
        this.time = new Date().toISOString().substring(0,16);
        let partner = JSON.parse(localStorage.getItem('partner'));
        if(partner != null) {
            this.partnername = partner.name;
            this.partnergender = partner.gender;
            this.partnernationality = partner.nationality;
        }
    }
}

</script>

<style>
.close {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    transform: rotate(45deg);
}

</style>