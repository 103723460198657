<template>
    <input type="text" placeholder="Nationality" @focus="dropdownVisible = true; searchString = ''; " v-model="searchString">
    <div class="dropdown" v-if="dropdownVisible">
        <table>
            <tr v-for="country in filteredList" :key="country.code">
                <td @click="clickCountry(country)">{{country.emoji}} {{country.name}}</td>
            </tr>
        </table>
    </div>
</template>

<script>
import countryList from './countries.json';
export default {
    name: 'countrySelector',
    props: {
        country: String,
    },
    data() {
        return {
            countryList,
            dropdownVisible: false ,
            searchString: ''
        }
    },
    methods: {
        clickCountry(country) {
            this.$emit('update:country', country.name)
            this.searchString = country.emoji + " " +country.name;
            this.dropdownVisible = false;
        },
        getEmoji() {
            let c = countryList.find(c => c.name == this.country);
            return (c != undefined) ? c.emoji : '';
        }
    },
    computed: {
        filteredList() {
            return this.countryList.filter(country => {
                return this.searchString
                .toLowerCase()
                .split(" ")
                .every(v => country.name.toLowerCase().includes(v))
            })
        }
    },
    watch: {
        country: function(val) {
            this.searchString =  this.getEmoji() + " " + val;
        }
    },
    created: function() {
        if(this.country != undefined){
            this.searchString =  this.getEmoji() + " " + this.country;
        }
    }
}
</script>


<style>

.dropdown {
    max-height: 200px;
    max-width: 300px;
    overflow-y: scroll;
    background: #F2D0D7;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.1);
    position: absolute;
    color: #594A4A;
    z-index: 10;
    border-radius: 5px;
    right: 10%;
    left: 10%;
    padding-left: 10px;
}

.dropdown table {
    text-align: left;
}

</style>