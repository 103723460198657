<template>
    <main>
        <h2 v-if="this.date"><img @click="decreaseMonth()" class="left" :src="'./assets/angle.svg'"/>{{this.date.toLocaleString('default', { month: 'long' })}} {{this.date.getFullYear()}}<img class="right" @click="increaseMonth()" :src="'./assets/angle.svg'"/></h2>
        <table>
            <tr><th>Sun</th><th>Mon</th><th>Tue</th><th>Wed</th><th>Thu</th><th>Fri</th><th>Sat</th></tr>
            <tr v-for="(n,index) in weeks" :key="index">
                <td v-for="(m,index) in 7" :key="index">
                    <span v-if="(n-1)*7+m-firstDay > 0 && (n-1)*7+m-firstDay <= lastDay">{{(n-1)*7+m-firstDay}}
                    {{this.allActivites[(n-1)*7+m-firstDay]}}
                    </span></td>
            </tr>
        </table>
    </main>
</template>

<script>
let date = new Date()
let allActivites = [];
allActivites[1] = 'test';
allActivites[4] = 'test';
allActivites[7] = 'test';
export default {
    name: 'calendar',
    data(){
        return {
            date,
            allActivites
        }
    },
    methods: {
        decreaseMonth() {
            this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
        },
        increaseMonth() {
            this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
        }
    },
    computed: {
        firstDay() {return new Date(this.date.getFullYear(), this.date.getMonth(), 1).getDay()},
        lastDay() {return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0).getDate()},
        weeks() {return Math.ceil((this.firstDay + this.lastDay)/7)}
    },
    created: function(){
        //allActivites = JSON.parse(localStorage.getItem('activites'));
        //console.log(allActivites);
    }
}
</script>

<style>
table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    table-layout: fixed ;
}

h2 {
    position: relative;
}

table td {
    border: solid 1px grey;
}

.left, .right {
    position: absolute;
    width: 30px;
    top: 3px;
}

.left {transform: rotate(90deg); left: 20px;}
.right {transform: rotate(-90deg); right: 20px;}

</style>