import {createRouter, createWebHistory} from 'vue-router'
import genderSelect from '../views/genderSelect.vue'
import addActivity from '../views/addActivity.vue'
import calendar from '../views/calendar.vue'
import statistics from '../views/statistics.vue'
import settings from '../views/settings.vue'
import about from '../views/about.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: calendar
    },
    {
        path: '/genderSelect',
        name: 'genderSelect',
        component: genderSelect
    },
    {
        path: '/addActivity',
        name: 'addActivity',
        component: addActivity
    }
    ,
    {
        path: '/calendar',
        name: 'calendar',
        component: calendar
    },
    {
        path: '/statistics',
        name: 'statistics',
        component: statistics
    },
    {
        path: '/about',
        name: 'about',
        component: about
    },
    {
        path: '/settings',
        name: 'settings',
        component: settings
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router