<template>
    <main>

    </main>
</template>

<script>
export default {
    name: 'statisticsView',
}
</script>