<template>
    <calendar></calendar>
</template>

<script>
import calendar from '../components/calendar.vue';
export default {
    name: 'calendarView',
    components: {
        calendar,
    },
    props: {
        gender: String
    }
}

</script>