<template>
    <nav>
        <router-link to="/calendar"><div><img :src="(this.$route.name=='calendar') ? './assets/calendaractive.svg' : './assets/calendar.svg'"/><span>Calendar</span></div></router-link>
        <router-link to="/statistics"><div><img :src="(this.$route.name=='statistics') ? './assets/statisticsactive.svg' : './assets/statistics.svg'"/><span>Satistics</span></div></router-link>
        <router-link to="/addActivity"><div class="add"><img :src="'./assets/add.svg'"/></div></router-link>
        <router-link to="/about"><div><img :src="(this.$route.name=='about') ? './assets/aboutactive.svg' : './assets/about.svg'"/><span>About</span></div></router-link>
        <router-link to="/settings"><div><img :src="(this.$route.name=='settings') ? './assets/settingsactive.svg' : './assets/settings.svg'"/><span>Settings</span></div></router-link>
    </nav>
</template>

<script>
export default {
    name: 'navigation'
}
</script>

<style>
nav {
    padding-top: 5px;
    background: #F2BDC7;
    height: 60px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

nav div img {
    width: 30px;
    margin-bottom: 5px;
}

nav div span, nav a {
    color: #F2E9E9;
    text-decoration: none;
}

nav .add {
    background: #F2BDC7;
    width: 80px;
    height: 80px;
    top: -20px;
    position: relative;
    border-radius: 40px;
}

nav .add img {
    width: 60px;
    margin: 10px;
}

nav div {
    text-align: center;
    font-size: 0.7em;
    display:flex;
    flex-direction: column;
    align-items: center;
}

</style>