<template>
<router-view v-model:gender="gender" v-slot="{ Component, route }">
  <transition name="fade" mode="out-in">
    <div :key="route.name">  
      <component :is="Component"></component>
    </div>
  </transition>
</router-view>
<navigation v-if="this.$route.name != 'addActivity' && this.$route.name != 'genderSelect'"></navigation>
</template>


<script>
import navigation from './components/navigation.vue';
export default {
  name: 'App',
  components: {
    navigation
  },
  data() {
    return {
      gender: ''
    }
  }
}
</script>

<style>
html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background: #F29BAB;
    font-size: 1.2em;
    color: #F2E9E9;
    font-weight: 400;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
  touch-action: pan-x pan-y;
}

h1, h2 {
    color: #F2D0D7;
    font-weight: 400;
}

h2 {
  margin-bottom: 5px;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

button {
    font-size: 1em;
    border-radius: 30px;
    padding: 10px 30px;
    color: #F2E9E9;
    background: #F2BDC7;
    border: 0;
    cursor: pointer;
    margin-bottom: 20px;
    margin-top: 20px;
}
button:active {
    background: #F2D0D7;
}
button:disabled {
    color: #f3e8e8;
    background: #e1acb6;
}

input[type=radio] + label, input[type=checkbox] + label {
    width: 100px;
    overflow: hidden;
    position: relative;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    line-height: 2;
    white-space: nowrap;
}

input[type=radio]:checked:checked + label, input[type=checkbox]:checked:checked + label {
    background-color:#F2BDC7;
}

input[type="radio"], input[type="checkbox"] {
    display: none;
}

label.bigimage { 
    white-space: normal !important;
}

.smallimage img {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-bottom;
}

input[type=datetime-local] {
  background: transparent;
  border: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1em;
  color: #F2E9E9;
  text-align: center;
}

input[type=datetime-local]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 0.9;
}

input[type="text"] {
  text-align: center;
  border-radius: 20px;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 0.8em;
  background: #F2D0D7;
  border: none;
  padding: 10px;
  margin: 5px;
  color: #594A4A;
  width: 250px;
}

.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transition: all ease .5s;
  transform: translateX(0);
}
.slide-leave {
  transition: all ease .5s;
}
.slide-leave-active {
  transform: translateX(-100%);
}


.color1{background: #F29BAB;}
.color2{background: #F2BDC7;}
.color3{background: #F2D0D7;}
.color4{background: #594A4A;}
.color5{background: #F2E9E9;}
</style>
