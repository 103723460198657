<template>
    <main>
        <h1 style="margin-top: 100px;">I have a ...</h1>
        <div class="row">
            <input type="radio" :checked="gender == 'penis'" id="penis" name="gender" value="penis" @change="(event)=>$emit('update:gender', event.target.value)">
            <label for="penis" class="bigimage">
                <img :src="'./assets/penis.svg'"/>
                <span>Penis</span>
            </label>
            <input type="radio" :checked="gender == 'vagina '" id="vagina" name="gender" value="vagina" @change="(event)=>$emit('update:gender', event.target.value)">
            <label for="vagina" class="bigimage">
                <img :src="'./assets/vagina.svg'"/>
                <span>Vagina</span>
            </label>
        </div>
        <button @click="saveGender">next</button>
    </main>
</template>

<script>
export default {
  name: 'genderSelectView',
  props: {
      gender: String
  },
  methods: {
    saveGender() {
        if(this.gender != '') {
            localStorage.setItem('gender', this.gender);
            this.$router.push('addActivity')
        }
    }
  },
  created: function() {
        if(this.gender == '' && localStorage.getItem('gender') != null ) {
            this.$emit('update:gender', localStorage.getItem('gender'));
            this.$router.push('addActivity')
        }
    }
}

</script>